import {
  Button,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Dialog,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { TDocument } from '@/types/document';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Trash01Icon } from '@/assets/icons/Trash01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  document: TDocument;
  onClose: () => void;
  onDocumentDeleted: (document: TDocument) => void;
}

const DocumentDeleteModal: React.FC<Props> = ({ document, onClose, onDocumentDeleted }) => {
  const { session } = useAuth();

  const deleteDocumentMutation = useMutation({
    mutationFn: async () => {
      await axios.delete(`${environment.api}/document/${document.id}`, {
        headers: { Authorization: `Bearer ${session?.access_token}` },
      });
    },
    onSuccess: () => {
      onDocumentDeleted(document);
      enqueueSnackbar(`Document ${document.original_name} deleted successfully.`, {
        variant: 'success',
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar(`Failed to delete document ${document.original_name}.`, { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {document.original_name} document?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          variant="contained"
          color="error"
          startIcon={<Trash01Icon />}
          loading={deleteDocumentMutation.isPending}
          onClick={() => deleteDocumentMutation.mutate()}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDeleteModal;
