interface Props {
  reverse?: boolean;
}

const LogoMain: React.FC<Props> = ({ reverse }) => (
  <img
    src="/assets/images/terrai-logo.svg"
    alt="Terr.ai"
    style={{ width: 'auto', height: '30px' }}
  />
);

export default LogoMain;
