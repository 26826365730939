import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

import Breadcrumbs from '@/@mantis/components/@extended/Breadcrumbs';

interface Breadcrumb {
  label: string;
  to?: string;
  onClick?: () => void;
}

interface Props {
  title: string;
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactNode;
}

const DashboardHeader: React.FC<Props> = ({ title, breadcrumbs, actions }) => (
  <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
    {title === 'Unnamed' ? (
      <Skeleton variant="rectangular" width={200} height={70} animation="wave" />
    ) : (
      <Breadcrumbs
        heading={title}
        custom
        links={
          breadcrumbs?.map(crumb => ({
            title: crumb.label,
            to: crumb.to,
            onClick: crumb.onClick,
          })) ?? []
        }
      />
    )}

    {!!actions && (
      <Stack direction="row" spacing={1} ml="auto">
        {actions}
      </Stack>
    )}
  </Stack>
);

export default DashboardHeader;
