import { Draggable } from '@hello-pangea/dnd';
import { Box, Button, Stack, Typography, useMediaQuery, Link, Chip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { TTicket } from '@/types/ticket';

import { Building01Icon } from '@/assets/icons/Building01Icon';
import { Home02Icon } from '@/assets/icons/Home02Icon';
import { User03Icon } from '@/assets/icons/User03Icon';
import { PMPRoutes } from '@/config/routes';
import { formatFullDateTime } from '@/utils/date';
import { getResolvedRoutePath } from '@/utils/router';
import { capitalizeFirstLetter } from '@/utils/text';

interface Props {
  ticket: TTicket;
  index: number;
  onTicketResolved?: (ticket: TTicket) => void;
}

const KanbanTicket: React.FC<Props> = ({ ticket, index, onTicketResolved }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Draggable draggableId={String(ticket.id)} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            userSelect: 'none',
            margin: 0,
            padding: '16px',
            border: '1px solid',
            borderColor: theme.palette.divider,
            background: alpha(theme.palette.background.paper, snapshot.isDragging ? 0.8 : 1),
            borderRadius: '4px',
            position: 'relative',
            overflow: 'hidden',
            ...provided.draggableProps.style,
          }}
        >
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h5" color="primary">
                #{ticket.id}
              </Typography>

              <Chip size="small" variant="light" label={capitalizeFirstLetter(ticket.intent)} />

              <Chip size="small" variant="light" label={ticket.email_id ? 'Email' : 'SMS'} />
            </Stack>

            {ticket.tenant && (
              <Stack direction="row" spacing={1} alignItems="center" sx={{ overflow: 'hidden' }}>
                <User03Icon />

                <Link
                  color="inherit"
                  underline="hover"
                  href={getResolvedRoutePath(PMPRoutes.tenantView, {
                    id: String(ticket.tenant.id),
                  })}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {ticket.tenant.full_name}
                </Link>
              </Stack>
            )}

            {ticket.unit && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center" sx={{ overflow: 'hidden' }}>
                  <Building01Icon />

                  <Link
                    href={getResolvedRoutePath(PMPRoutes.buildingView, {
                      id: String(ticket.unit.building.id),
                    })}
                    color="inherit"
                    underline="hover"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {ticket.unit.building.name}
                  </Link>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center" sx={{ overflow: 'hidden' }}>
                  <Home02Icon />

                  <Link
                    href={getResolvedRoutePath(PMPRoutes.unitView, {
                      id: ticket.unit.id.toString(),
                    })}
                    color="inherit"
                    underline="hover"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Unit {ticket.unit.name}
                  </Link>
                </Stack>
              </Stack>
            )}

            <Typography variant="body2">{ticket.message}</Typography>

            <Typography variant="caption" color="textSecondary">
              {formatFullDateTime(new Date(ticket.created_at))}
            </Typography>

            {isMobile && !ticket.resolved && !!onTicketResolved && (
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => onTicketResolved?.(ticket)}
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                }}
              >
                Resolve
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </Draggable>
  );
};

export default KanbanTicket;
