export default function OverridesDialogTitle() {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
        },
      },
    },
  };
}
