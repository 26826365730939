import { closeSnackbar, SnackbarKey } from 'notistack';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { XCircleIcon } from '@/assets/icons/XCircleIcon';

const NotistackCloseIcon: React.FC<SnackbarKey> = snackbarKey => (
  <IconButton color="default" size="small" onClick={() => closeSnackbar(snackbarKey)}>
    <XCircleIcon />
  </IconButton>
);

export default NotistackCloseIcon;
