import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { environment } from '@env';

import App from './App';
import { VERSION } from './environments/version';

Sentry.init(
  {
    dsn: environment.sentryDsn,
    environment: environment.stage,
    dist: VERSION.hash,
    enabled: window.location.hostname !== 'localhost',
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/terrai.*\.web\.app\/api/,
      /^https:\/\/.*terr\.ai\/api/,
      /^https:\/\/terrai-431617.*\.web\.app\/api/,
    ],
    integrations: [
      Sentry.browserTracingIntegration(), // as IntegrationType,
      Sentry.replayIntegration({}),
    ], // why type mismatch here?

    ignoreErrors: [
      // Ignore all `ResizeObserver loop limit exceeded` errors
      'ResizeObserver loop limit exceeded',
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: environment.stage === 'production' ? 0.1 : 0.0,
    replaysOnErrorSampleRate: environment.stage === 'production' ? 1.0 : 0.0,
  },
  // Forward the init method from @sentry/react
  SentryReact.init
);

const container = document.getElementById('root');
if (!container) {
  throw new Error('No root element found');
}
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
