import { ListItemAvatar, ListItemText, Skeleton, Stack, Typography } from '@mui/material';

import { MessageSessionType, TMessageSession } from '@/types/message';

import Avatar from '@/@mantis/components/@extended/Avatar';
import { getPaletterColorFromString } from '@/utils/color';
import { getFromNow } from '@/utils/date';
import { formatPhoneNumber } from '@/utils/phone';
import { getInitialsFromFullName } from '@/utils/user';

interface Props {
  messageSession: TMessageSession | null;
  isLoading?: boolean;
}

const MessageSessionListItemContent: React.FC<Props> = ({ messageSession, isLoading = false }) => {
  if (isLoading || !messageSession)
    return (
      <>
        <ListItemAvatar>
          <Skeleton
            animation={isLoading ? 'wave' : false}
            variant="circular"
            width={40}
            height={40}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton animation={isLoading ? 'wave' : false} height={24} width={50} />}
          secondary={<Skeleton animation={isLoading ? 'wave' : false} height={20} width={80} />}
        />
      </>
    );

  return (
    <>
      <ListItemAvatar>
        <Avatar
          color={getPaletterColorFromString(messageSession.session_id)}
          type="filled"
          sx={{ width: 40, height: 40 }}
        >
          {getInitialsFromFullName(messageSession.from.name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="h5"
            color="text.primary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {messageSession.subject}
          </Typography>
        }
        secondary={
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {getFromNow(new Date(messageSession.updated_at))} • {messageSession.from.name} &lt;
              {messageSession.type === MessageSessionType.sms
                ? formatPhoneNumber(messageSession.from.source)
                : messageSession.from.source}
              &gt;
            </Typography>
          </Stack>
        }
      />
    </>
  );
};

export default MessageSessionListItemContent;
