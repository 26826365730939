import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { ChartBreakoutSquareIcon } from '@/assets/icons/ChartBreakoutSquareIcon';
import { MessageChatCircleIcon } from '@/assets/icons/MessageChatCircleIcon';
import { ZapIcon } from '@/assets/icons/ZapIcon';

const reasons = [
  {
    Icon: MessageChatCircleIcon,
    title: 'Human Sounding AI Responses',
    description:
      "AI-powered responses feel natural and personal, delivering instant, contextually aware answers to tenant inquiries while maintaining your property's professional tone.",
  },
  {
    Icon: ZapIcon,
    title: 'Streamlined Workflows',
    description:
      'Your Property Management Copilot handles the heavy lifting - from instant tenant communications to smart ticket routing.',
  },
  {
    Icon: ChartBreakoutSquareIcon,
    title: 'Real-time Problem Solving',
    description:
      'Turn every tenant interaction into immediate action. Your copilot intelligently analyzes requests, routes urgent matters, and provides solutions instantly - no more waiting or missed communications.',
  },
];

const WhyUsSection: React.FC = () => (
  <Grid2 size={12}>
    <Box sx={{ py: 6, textAlign: 'center' }}>
      <Stack spacing={0.75} sx={{ maxWidth: 770, margin: '0 auto', mb: 4 }}>
        <Typography variant="body2" color="primary" sx={{ fontWeight: 600 }}>
          Why Choose Us?
        </Typography>

        <Typography variant="h2" mb={1}>
          Your 24/7 Property Management Copilot.
          <br />
          <strong>Never miss a beat.</strong>
        </Typography>

        <Typography variant="body1" color="text.secondary">
          Manage properties smarter with AI, real-time insights, and easy customization.
          <br />
          Save time, stay organized, and keep tenants happy effortlessly.
        </Typography>
      </Stack>

      <Box sx={{ borderTop: '1px solid #D9D9D9' }}>
        <Grid2 container>
          {reasons.map(reason => (
            <Grid2 key={reason.title} size={{ xs: 12, sm: 4 }}>
              <Stack spacing={0.5} sx={{ p: 2.5, alignItems: 'center', flexBasis: '33%' }}>
                <Box
                  sx={{
                    height: 48,
                    width: 48,
                    borderRadius: '10px',
                    border: '1px solid #D9D9D9',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 1.25,
                  }}
                >
                  <reason.Icon fontSize="small" color="primary" />
                </Box>

                <Typography variant="h4">{reason.title}</Typography>

                <Typography variant="body2">{reason.description}</Typography>
              </Stack>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </Box>
  </Grid2>
);

export default WhyUsSection;
