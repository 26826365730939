import { Droppable } from '@hello-pangea/dnd';
import {
  Box,
  Stack,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';

import { TTicket } from '@/types/ticket';

import KanbanTicket from './KanbanTicket';

interface Props {
  title: string;
  tickets: TTicket[];
  droppableId: string;
  onTicketResolved?: (ticket: TTicket) => void;
}

const KanbanColumn: React.FC<Props> = ({ title, tickets, droppableId, onTicketResolved }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isExpanded, setIsExpanded] = useState(!isMobile);

  useEffect(() => {
    setIsExpanded(!isMobile);
  }, [isMobile]);

  return (
    <Accordion expanded={isExpanded} onChange={(_, expanded) => setIsExpanded(expanded)}>
      <AccordionSummary>
        <Typography variant="h5">
          {title} ({tickets.length})
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Droppable droppableId={droppableId} type="item">
          {(providedDrop, snapshotDrop) => (
            <Box
              ref={providedDrop.innerRef}
              {...providedDrop.droppableProps}
              sx={{
                padding: '16px',
                width: 'auto',
                borderRadius: '4px',
                background: snapshotDrop.isDraggingOver
                  ? alpha(theme.palette.secondary.light, 0.65)
                  : theme.palette.secondary.lighter,
              }}
            >
              <Stack spacing={1}>
                {tickets.map((ticket, index) => (
                  <KanbanTicket
                    key={ticket.id}
                    ticket={ticket}
                    index={index}
                    onTicketResolved={onTicketResolved}
                  />
                ))}

                {providedDrop.placeholder}
              </Stack>
            </Box>
          )}
        </Droppable>
      </AccordionDetails>
    </Accordion>
  );
};

export default KanbanColumn;
