import { PMPRoutes } from '@/config/routes';

import { DefaultConfigProps } from './types/config';

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const APP_DEFAULT_PATH = PMPRoutes.dashboard;
export const HORIZONTAL_MAX_ITEM = 7;
export const DRAWER_WIDTH = 260;
export const MINI_DRAWER_WIDTH = 60;

export enum SimpleLayoutType {
  SIMPLE = 'simple',
  LANDING = 'landing',
}

export enum ThemeDirection {
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum NavActionType {
  FUNCTION = 'function',
  LINK = 'link',
}

export enum DropzopType {
  DEFAULT = 'default',
  STANDARD = 'standard',
}

const config: DefaultConfigProps = {
  fontFamily: "'Inter', sans-serif",
  i18n: 'en',
  miniDrawer: false,
  container: true,
  themeDirection: ThemeDirection.LTR,
};

export default config;
