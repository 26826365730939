import LinkBehavior from '@/@mantis/theme/components/LinkBehavior';

export default function OverridesLink() {
  return {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        underline: 'hover',
      },
    },
  };
}
