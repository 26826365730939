import { PaletteThemeProps } from '@/@mantis/types/theme';

export default function TerraiTheme(): PaletteThemeProps {
  const grey = {
    25: '#FDFDFD',
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#E9EAEB',
    300: '#D5D7DA',
    400: '#A4A7AE',
    500: '#717680',
    600: '#535862',
    700: '#414651',
    800: '#252B37',
    900: '#181D27',
    950: '#0A0D12',
  };
  const primary = {
    25: '#FFF4F3',
    50: '#FFE7E3',
    100: '#FFD6CF',
    200: '#FFBEB4',
    300: '#FFA192',
    400: '#C41900',
    500: '#9D1500',
    600: '#8D1300',
    700: '#781000',
    800: '#630D00',
    900: '#4E0A00',
    950: '#190302',
  };
  const error = {
    25: '#FFFAFA',
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
    950: '#450A0A',
  };
  const success = {
    25: '#FAFFFD',
    50: '#F1FEF9',
    100: '#D5FBEB',
    200: '#A9F4D7',
    300: '#71EAC2',
    400: '#38D6A4',
    500: '#10B785',
    600: '#039166',
    700: '#027351',
    800: '#055C40',
    900: '#054834',
    950: '#01271C',
  };
  const warning = {
    25: '#FFFEF5',
    50: '#FEFCE8',
    100: '#FEF9C3',
    200: '#FEF08A',
    300: '#FDE047',
    400: '#FACC15',
    500: '#EAB308',
    600: '#CA8A04',
    700: '#A16207',
    800: '#854D0E',
    900: '#713F12',
    950: '#422006',
  };

  return {
    primary: {
      lighter: primary[50],
      100: primary[100],
      200: primary[200],
      light: primary[300],
      400: primary[400],
      main: primary[500],
      dark: primary[600],
      700: primary[700],
      darker: primary[800],
      900: primary[900],
      contrastText: primary[25],
    },
    secondary: {
      lighter: grey[50],
      100: grey[100],
      200: grey[200],
      light: grey[300],
      400: grey[400],
      main: grey[500],
      600: grey[600],
      dark: grey[700],
      800: grey[800],
      darker: grey[900],
      A100: grey[900],
      A200: grey[400],
      A300: grey[300],
      contrastText: grey[25],
    },
    error: {
      lighter: error[50],
      light: error[300],
      main: error[500],
      dark: error[700],
      darker: error[900],
      contrastText: error[25],
    },
    warning: {
      lighter: warning[50],
      light: warning[300],
      main: warning[500],
      dark: warning[700],
      darker: warning[900],
      contrastText: warning[25],
    },
    info: {
      lighter: grey[50],
      light: grey[300],
      main: grey[500],
      dark: grey[700],
      darker: grey[900],
      contrastText: grey[25],
    },
    success: {
      lighter: success[50],
      light: success[300],
      main: success[500],
      dark: success[700],
      darker: success[900],
      contrastText: success[25],
    },
    grey: {
      0: grey[25],
      50: grey[50],
      100: grey[100],
      200: grey[200],
      300: grey[300],
      400: grey[400],
      500: grey[500],
      600: grey[600],
      700: grey[700],
      800: grey[800],
      900: grey[900],
      A50: grey[25],
      A100: grey[900],
      A200: grey[400],
      A400: grey[200],
      A700: grey[100],
      A800: grey[500],
    },
  };
}
