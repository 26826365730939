import { Button, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

const HaveQuestionsSection: React.FC = () => (
  <Grid2 size={12}>
    <Stack
      direction="column"
      spacing={2}
      sx={{
        backgroundColor: '#9D150005',
        px: 2,
        pt: 2,
        pb: 2.5,
        mb: 6,
        border: '1px solid #9D150033',
        borderRadius: '16px',
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <img src="/assets/images/avatar-group.png" alt="People" style={{ width: 120 }} />

      <Stack spacing={0.5}>
        <Typography variant="h4">Still have questions?</Typography>
        <Typography variant="body1" sx={{ fontSize: 18 }} color="text.secondary">
          Can’t find the answer you’re looking for? Please chat to our friendly team.
        </Typography>
      </Stack>

      <Button variant="contained" color="primary" LinkComponent="a" href="mailto:hello@terr.ai">
        Get in touch
      </Button>
    </Stack>
  </Grid2>
);

export default HaveQuestionsSection;
