import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TBuilding } from '@/types/building';
import { TPropertyLocation } from '@/types/propertyLocation';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { useAuth } from '@/context/AuthProvider';

import AddressAutocomplete from '../address/AddressAutocomplete';
import PropertyLocationAutocomplete from '../propertyLocation/PropertyLocationAutocomplete';

interface Props {
  propertyLocationId?: number;
  onClose: () => void;
  onBuildingCreated: (building: TBuilding) => void;
}

const BuildingCreateModal: React.FC<Props> = ({
  propertyLocationId,
  onClose,
  onBuildingCreated,
}) => {
  const [name, setName] = useState('');
  const [addressText, setAddressText] = useState('');
  const [selectedProperty, setSelectedProperty] = useState<TPropertyLocation | null>(null);

  const { session } = useAuth();

  const createBuildingMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TBuilding>(
        `${environment.api}/building`,
        {
          name,
          property_location_id: selectedProperty?.id,
          address_text: addressText,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      onBuildingCreated(data);
      enqueueSnackbar(`Building ${data.name} created successfully.`, {
        variant: 'success',
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create building.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create Building</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />

        <AddressAutocomplete
          value={addressText}
          onValueChange={setAddressText}
          textFieldProps={{
            fullWidth: true,
            margin: 'dense',
          }}
        />

        <PropertyLocationAutocomplete
          defaultPropertyLocationId={propertyLocationId}
          value={selectedProperty}
          onValueChange={setSelectedProperty}
          textFieldProps={{
            fullWidth: true,
            margin: 'dense',
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          disabled={!name || !addressText || !selectedProperty}
          variant="contained"
          color="primary"
          startIcon={<PlusIcon />}
          loading={createBuildingMutation.isPending}
          onClick={() => createBuildingMutation.mutate()}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BuildingCreateModal;
