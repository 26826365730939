import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useLayoutEffect, useState } from 'react';

import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import menuItem from '@/@mantis/menu-items';
import { NavItemType } from '@/@mantis/types/menu';

import NavGroup from './NavGroup';
import NavItem from './NavItem';

const Navigation: React.FC = () => {
  const { isOpen } = useDrawer();

  const [selectedID, setSelectedID] = useState<string | undefined>('');
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] });

  useLayoutEffect(() => {
    setMenuItems(menuItem);
    // eslint-disable-next-line
  }, [menuItem]);

  const lastItemIndex = menuItems.items.length - 1;
  const remItems: NavItemType[] = [];

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item, index) => {
    switch (item.type) {
    case 'group':
      if (item.url && item.id !== undefined) {
        return (
          <List key={item.id}>
            {index !== 0 && <Divider sx={{ my: 0.5 }} />}
            <NavItem item={item} level={1} isParents setSelectedID={setSelectedID} />
          </List>
        );
      }

      return (
        <NavGroup
          key={item.id}
          setSelectedID={setSelectedID}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedID={selectedID}
          selectedItems={selectedItems}
          remItems={remItems}
          item={item}
        />
      );
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
        </Typography>
      );
    }
  });

  return (
    <Box
      sx={{
        pt: !isOpen ? 0 : 2,
        display: 'block',
        '& > ul:first-of-type': { mt: 0 },
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
