import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ComponentType } from 'react';

import MainCard from '@/@mantis/components/MainCard';

interface Props {
  primary: string | number;
  secondary: string;
  iconPrimary: ComponentType;
  color?: string;
  onClick?: () => void;
}

const ReportCard: React.FC<Props> = ({ primary, secondary, iconPrimary, color, onClick }) => {
  const IconPrimary = iconPrimary;

  return (
    <MainCard
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        '&:hover': onClick
          ? {
            transform: 'translateY(-3px)',
            transition: 'all .3s ease-in-out',
          }
          : {},
      }}
      onClick={onClick}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Stack spacing={1}>
            <Typography variant="h4">{primary}</Typography>
            <Typography variant="body1" color="textSecondary">
              {secondary}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <IconPrimary
            // @ts-ignore
            sx={{ fontSize: '2.5rem', color: color || 'primary.main' }}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ReportCard;
