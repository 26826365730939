import { css } from '@emotion/react';
import { TypographyVariantsOptions } from '@mui/material/styles';

import { FontFamily } from '@/@mantis/types/config';

export const fontDefinitions = css`
  @font-face {
    font-family: 'Nohemi';
    src: url('/assets/fonts/Nohemi-VF.ttf');
    font-weight: 100 1000;
  }
`;

export default function Typography(
  fontFamily: FontFamily = "'Inter', sans-serif"
): TypographyVariantsOptions {
  return {
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: 'Nohemi',
      fontSize: '2.25rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.025em',
    },
    h2: {
      fontFamily: 'Nohemi',
      fontSize: '1.875rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.025em',
    },
    h3: {
      fontFamily: 'Nohemi',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.025em',
    },
    h4: {
      fontFamily: 'Nohemi',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.025em',
    },
    h5: {
      fontFamily: 'Nohemi',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.025em',
    },
    h6: {
      fontFamily: 'Nohemi',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.025em',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.003em',
    },
    body1: {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '-0.0025em',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1,
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1,
    },
    overline: {
      lineHeight: 1.5,
    },
    button: {
      textTransform: 'capitalize',
      fontWeight: 600,
      fontSize: '0.875rem',
    },
  };
}
