import { useEffect } from 'react';
import { SSE, SSEvent } from 'sse.js';

type SSEConfig = {
  endpoint: string;
  headers: Record<string, string>;
  onMessage: (event: SSEvent) => void;
  onError?: () => void;
  reconnectDelay?: number;
  enabled?: boolean;
};

type SSESubscriber = {
  onMessage: (event: SSEvent) => void;
  onError?: () => void;
};

type SSEConnection = {
  client: SSE;
  subscribers: Set<SSESubscriber>;
  inactivityTimer: NodeJS.Timeout | null;
};

class SSEConnectionCache {
  // eslint-disable-next-line no-use-before-define
  private static instance: SSEConnectionCache;

  private connections: Map<string, SSEConnection> = new Map();

  static getInstance(): SSEConnectionCache {
    if (!SSEConnectionCache.instance) {
      SSEConnectionCache.instance = new SSEConnectionCache();
    }
    return SSEConnectionCache.instance;
  }

  private createConnection(
    endpoint: string,
    headers: Record<string, string>,
    reconnectDelay: number
  ): SSEConnection {
    const client = new SSE(endpoint, { headers });
    const connection: SSEConnection = {
      client,
      subscribers: new Set(),
      inactivityTimer: null,
    };

    const handleMessage = (event: SSEvent) => {
      connection.subscribers.forEach(subscriber => subscriber.onMessage(event));
    };

    const handleError = () => {
      connection.subscribers.forEach(subscriber => subscriber.onError?.());

      this.connections.delete(endpoint);

      // Attempt to reconnect if we still have subscribers
      setTimeout(() => {
        if (this.connections.has(endpoint)) {
          return;
        }

        const activeConnection = this.getOrCreateConnection(endpoint, headers, reconnectDelay);
        activeConnection.client.stream();
      }, reconnectDelay);
    };

    client.addEventListener('message', handleMessage);
    client.addEventListener('error', handleError);

    this.connections.set(endpoint, connection);
    return connection;
  }

  private getOrCreateConnection(
    endpoint: string,
    headers: Record<string, string>,
    reconnectDelay: number
  ): SSEConnection {
    const existing = this.connections.get(endpoint);
    return existing ?? this.createConnection(endpoint, headers, reconnectDelay);
  }

  subscribe(
    endpoint: string,
    headers: Record<string, string>,
    subscriber: SSESubscriber,
    reconnectDelay: number
  ) {
    const connection = this.getOrCreateConnection(endpoint, headers, reconnectDelay);

    if (connection.inactivityTimer) {
      clearTimeout(connection.inactivityTimer);
      connection.inactivityTimer = null;
    }

    connection.subscribers.add(subscriber);

    if (connection.subscribers.size === 1) {
      connection.client.stream();
    }
  }

  unsubscribe(endpoint: string, subscriber: SSESubscriber) {
    const connection = this.connections.get(endpoint);
    if (!connection) return;

    connection.subscribers.delete(subscriber);

    // Start inactivity timer if no subscribers left
    if (connection.subscribers.size === 0 && !connection.inactivityTimer) {
      connection.inactivityTimer = setTimeout(() => {
        connection.client.close();
        this.connections.delete(endpoint);
      }, 60000); // 1 minute timeout
    }
  }
}

const sseCache = SSEConnectionCache.getInstance();

export const useSSE = ({
  endpoint,
  headers,
  onMessage,
  onError,
  reconnectDelay = 5000,
  enabled = true,
}: SSEConfig) => {
  useEffect(() => {
    if (!enabled) return;

    const subscriber = { onMessage, onError };

    sseCache.subscribe(endpoint, headers, subscriber, reconnectDelay);

    return () => {
      sseCache.unsubscribe(endpoint, subscriber);
    };
  }, [endpoint, headers, onMessage, onError, enabled, reconnectDelay]);
};
