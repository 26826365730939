import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TTenant } from '@/types/tenant';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  tenant: TTenant;
  onClose: () => void;
  onTenantUpdated: (unit: TTenant) => void;
}

const TenantUpdateModal: React.FC<Props> = ({ tenant, onClose, onTenantUpdated }) => {
  const [fullName, setFullName] = useState(tenant.full_name);
  const [contacts, setContacts] = useState(tenant.contacts);

  const { session } = useAuth();

  const setPhone = (index: number, phone: string) => {
    setContacts(prevContacts => {
      const newContacts = [...prevContacts];
      newContacts[index] = { ...newContacts[index], phone };
      return newContacts;
    });
  };

  const setEmail = (index: number, email: string) => {
    setContacts(prevContacts => {
      const newContacts = [...prevContacts];
      newContacts[index] = { ...newContacts[index], email };
      return newContacts;
    });
  };

  const editTenantMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.put<TTenant>(
        `${environment.api}/tenant/${tenant.id}`,
        { full_name: fullName, contacts },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onTenantUpdated(data);
      enqueueSnackbar(`Tenant ${data.full_name} updated successfully.`, { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update tenant.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Edit Tenant</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2} mt={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={e => setFullName(e.target.value)}
          />
          {contacts.map((contact, index) => (
            <Stack direction="row" spacing={2} key={contact.id}>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                value={contact.email}
                onChange={e => setEmail(index, e.target.value)}
              />

              <TextField
                margin="dense"
                label="Phone"
                type="phone"
                fullWidth
                value={contact.phone}
                onChange={e => setPhone(index, e.target.value)}
              />
            </Stack>
          ))}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          disabled={!fullName || editTenantMutation.isPending}
          variant="contained"
          color="primary"
          startIcon={<Save01Icon />}
          loading={editTenantMutation.isPending}
          onClick={() => editTenantMutation.mutate()}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TenantUpdateModal;
