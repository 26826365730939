import { Theme } from '@mui/material/styles';

export default function OverridesAccordionDetails(theme: Theme) {
  return {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          borderTop: '1px solid',
          borderTopColor: theme.palette.secondary.light,
        },
      },
    },
  };
}
