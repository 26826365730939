import { Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

const RedefiningFutureSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 6 }}>
    <Stack spacing={2.5} sx={{ textAlign: 'center', maxWidth: 770, margin: '0 auto' }}>
      <Typography variant="h2" sx={{ fontWeight: 600 }}>
        Redefining the Future of Property&nbsp;Management
      </Typography>

      <Typography variant="body1" color="text.secondary">
        Revolutionizing property management by leveraging artificial intelligence to streamline
        operations, boost efficiency, and enhance tenant experiences.
      </Typography>
    </Stack>
  </Grid2>
);

export default RedefiningFutureSection;
