export const formatWeekdayDate = (date: Date = new Date()): string =>
  new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    weekday: 'short',
    month: 'short',
  }).format(date);

export const formatMonthYearDate = (date: Date = new Date()): string =>
  new Intl.DateTimeFormat(undefined, {
    month: 'short',
    year: 'numeric',
  }).format(date);

export const formatFullDate = (date: Date = new Date()): string =>
  new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);

export const formatFullDateTime = (date: Date = new Date()): string =>
  new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);

export const getDifferenceInMs = (fromDate: Date, toDate: Date): number =>
  toDate.getTime() - fromDate.getTime();

export interface TimeUnitValue {
  value: number;
  unit: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
}

export const timeUnitsInMs: TimeUnitValue[] = [
  {
    unit: 'year',
    value: 365 * 24 * 60 * 60 * 1000,
  },
  {
    unit: 'month',
    value: 30 * 24 * 60 * 60 * 1000,
  },
  {
    unit: 'day',
    value: 24 * 60 * 60 * 1000,
  },
  {
    unit: 'hour',
    value: 60 * 60 * 1000,
  },
  {
    unit: 'minute',
    value: 60 * 1000,
  },
  {
    unit: 'second',
    value: 1000,
  },
];

export const getDifference = (fromDate: Date, toDate: Date): TimeUnitValue => {
  const diffInMs = getDifferenceInMs(fromDate, toDate);

  for (const unitInMs of timeUnitsInMs) {
    if (Math.abs(diffInMs) >= unitInMs.value) {
      return {
        value: Math.floor(diffInMs / unitInMs.value),
        unit: unitInMs.unit,
      };
    }
  }

  return {
    value: 0,
    unit: 'second',
  };
};

export const getFromNow = (date: Date): string => {
  const { value, unit } = getDifference(new Date(), date);

  return new Intl.RelativeTimeFormat().format(value, unit);
};
