import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  Theme,
  TypographyVariantsOptions,
} from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import useConfig from '@/@mantis/hooks/useConfig';
import { CustomShadowProps } from '@/@mantis/types/theme';

import componentsOverride from './overrides';
import Palette from './palette';
import CustomShadows from './shadows';
import Typography, { fontDefinitions } from './typography';

type ThemeCustomizationProps = {
  children: ReactNode;
  themeOverrides?: (theme: Theme) => Theme;
};

const ThemeCustomization = ({ children, themeOverrides }: ThemeCustomizationProps) => {
  const { themeDirection, fontFamily } = useConfig();

  const theme: Theme = useMemo<Theme>(() => Palette(), []);

  const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
    () => Typography(fontFamily),
    [fontFamily]
  );

  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(
    () => CustomShadows(theme),
    [theme]
  );

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
        },
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes: Theme = useMemo(() => {
    let _themes: Theme = createTheme(themeOptions);
    _themes = themeOverrides?.(_themes) ?? _themes;
    _themes.components = componentsOverride(_themes);

    return _themes;
  }, [themeOverrides, themeOptions]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline enableColorScheme />
        <Global styles={fontDefinitions} />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeCustomization;
