import { CheckboxProps } from '@mui/material/Checkbox';
import { Theme } from '@mui/material/styles';

import { ExtendedStyleProps } from '@/@mantis/types/extended';
import getColors from '@/@mantis/utils/getColors';
import { CheckSquareIcon } from '@/assets/icons/CheckSquareIcon';
import { MinusSquareIcon } from '@/assets/icons/MinusSquareIcon';
import { SquareIcon } from '@/assets/icons/SquareIcon';

function getColorStyle({ color, theme }: ExtendedStyleProps & { theme: Theme }) {
  const colors = getColors(theme, color);
  const { lighter, main, dark } = colors;

  return {
    '&:hover': {
      backgroundColor: lighter,
      '& .icon': {
        borderColor: main,
      },
    },
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: -4,
    },
  };
}

interface CheckboxSizeProps {
  fontSize: number;
}

function getSizeStyle(size?: CheckboxProps['size']): CheckboxSizeProps {
  switch (size) {
  case 'small':
    return { fontSize: 1.15 };
  case 'large':
    return { fontSize: 1.6 };
  case 'medium':
  default:
    return { fontSize: 1.35 };
  }
}

function checkboxStyle(size?: CheckboxProps['size']) {
  const sizes: CheckboxSizeProps = getSizeStyle(size);

  return {
    '& .icon': {
      fontSize: `${sizes.fontSize}rem`,
    },
  };
}

export default function OverridesCheckbox(theme: Theme) {
  const { palette } = theme;

  return {
    MuiCheckbox: {
      defaultProps: {
        className: 'size-small',
        icon: <SquareIcon className="icon" />,
        checkedIcon: <CheckSquareIcon className="icon" />,
        indeterminateIcon: <MinusSquareIcon className="icon" />,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          color: palette.secondary[300],
          '&.size-small': {
            ...checkboxStyle('small'),
          },
          '&.size-medium': {
            ...checkboxStyle('medium'),
          },
          '&.size-large': {
            ...checkboxStyle('large'),
          },
        },
        colorPrimary: getColorStyle({ color: 'primary', theme }),
        colorSecondary: getColorStyle({ color: 'secondary', theme }),
        colorSuccess: getColorStyle({ color: 'success', theme }),
        colorWarning: getColorStyle({ color: 'warning', theme }),
        colorInfo: getColorStyle({ color: 'info', theme }),
        colorError: getColorStyle({ color: 'error', theme }),
      },
    },
  };
}
