import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import Editor from 'react-simple-wysiwyg';

import { environment } from '@env';

import { TBuildingContact } from '@/types/building';
import { TEmailTemplate } from '@/types/email';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { useAuth } from '@/context/AuthProvider';
import { formatPhoneNumber } from '@/utils/phone';

import EmailAttachmentInput from './EmailAttachmentInput';

interface Props {
  buildingId: number;
  onClose: () => void;
  onBulkEmailSent?: () => void;
}

const EmailBulkSendModal: React.FC<Props> = ({ buildingId, onClose, onBulkEmailSent }) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [signature, setSignature] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<TBuildingContact[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TEmailTemplate | null>(null);
  const [attachments, setAttachments] = useState<FileList | null>(null);

  const { session } = useAuth();

  const fetchBuildingContactsQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'building', buildingId, 'contacts', session?.access_token],
    queryFn: async () => {
      try {
        const response = await axios.get<TBuildingContact[]>(
          `${environment.api}/building/${buildingId}/contacts`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );

        const contactsWithEmail = response.data.filter(contact => contact.email);

        setSelectedContacts(contactsWithEmail);

        return contactsWithEmail;
      } catch {
        enqueueSnackbar('Failed to fetch building contacts.', { variant: 'error' });
      }
    },
  });

  useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'building', buildingId, 'signature', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<{ signature: string }>(
        `${environment.api}/building/${buildingId}/signature`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      setSignature(response.data.signature);

      return response.data;
    },
  });

  const fetchEmailTemplatesQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'building', buildingId, 'email-templates', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<TEmailTemplate[]>(
        `${environment.api}/building/${buildingId}/email-templates`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
  });

  const sendBulkEmailMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.postForm(
        `${environment.api}/building/${buildingId}/send-bulk-email`,
        {
          subject,
          body: signature ? `${body}<br><br>${signature}` : body,
          recipients: selectedContacts.map(contact => contact.email),
          attachments,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: () => {
      onBulkEmailSent?.();
      enqueueSnackbar('Bulk email sent successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to send bulk email.', { variant: 'error' });
    },
  });

  const handleTemplateChange = (template: TEmailTemplate | null) => {
    setSelectedTemplate(template);
    if (template) {
      setSubject(template.subject);
      setBody(
        template.body
          .replace(/%%PROPERTY_NAME%%/g, template.location?.name || '')
          .replace(/%%PROPERTY_EMAIL%%/g, template.location?.email || '')
          .replace(
            /%%PROPERTY_PHONE_NUMBER%%/g,
            template.location?.phone ? formatPhoneNumber(template.location.phone) : ''
          )
      );
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Bulk Email</DialogTitle>

      <DialogContent>
        <Autocomplete
          value={selectedTemplate}
          onChange={(_, newValue) => handleTemplateChange(newValue)}
          options={fetchEmailTemplatesQuery.data || []}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Select Template" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ mt: 2 }}
        />

        <TextField
          fullWidth
          label="Subject"
          value={subject}
          onChange={e => setSubject(e.target.value)}
          margin="normal"
        />

        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
          Body
        </Typography>

        <Editor
          containerProps={{
            style: {
              minHeight: '200px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              marginBottom: '16px',
            },
          }}
          value={body}
          onChange={e => setBody(e.target.value)}
        />

        <Editor
          containerProps={{
            style: {
              minHeight: '150px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              marginBottom: '16px',
            },
          }}
          value={signature}
          onChange={e => setSignature(e.target.value)}
          placeholder="Email signature"
        />

        <EmailAttachmentInput value={attachments} multiple onChange={setAttachments} />

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Recipients
        </Typography>

        <Autocomplete
          multiple
          value={selectedContacts}
          onChange={(_, newValue) => setSelectedContacts(newValue)}
          options={fetchBuildingContactsQuery.data || []}
          getOptionLabel={option =>
            `${option.tenantName} (Unit ${option.unitName}) <${option.email}>`
          }
          renderInput={params => <TextField {...params} label="Select Recipients" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          loading={fetchBuildingContactsQuery.isLoading}
          sx={{ mt: 1 }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          onClick={() => sendBulkEmailMutation.mutate()}
          variant="contained"
          startIcon={<Send01Icon />}
          color="primary"
          disabled={
            selectedContacts.length === 0 ||
            !subject ||
            !body ||
            fetchBuildingContactsQuery.isPending
          }
          loading={sendBulkEmailMutation.isPending}
        >
          Send Email
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EmailBulkSendModal;
