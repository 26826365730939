import { Theme } from '@mui/material/styles';

import Typography from '../typography';

export default function OverridesToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '12px 16px',
          color: theme.palette.grey[800],
          border: '1px solid',
          borderColor: `${theme.palette.divider} !important`,
          ...Typography().button,
          textTransform: 'none',
          lineHeight: 1,

          '&:hover': {
            backgroundColor: theme.palette.grey[50],
          },

          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: theme.palette.grey[100],
          },

          '&.Mui-disabled': {
            borderColor: theme.palette.divider,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
  };
}
