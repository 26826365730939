import { Autocomplete, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { TPropertyLocation } from '@/types/propertyLocation';

import { useAuth } from '@/context/AuthProvider';

interface Props {
  defaultPropertyLocationId?: number;
  value: TPropertyLocation | null;
  textFieldProps?: TextFieldProps;
  onValueChange: (value: TPropertyLocation | null) => void;
}

const PropertyLocationAutocomplete: React.FC<Props> = ({
  defaultPropertyLocationId,
  value,
  textFieldProps,
  onValueChange,
}) => {
  const { session } = useAuth();

  const fetchPropertyLocationsQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'property-location', 'all', session?.access_token],
    queryFn: async () => {
      try {
        const response = await axios.get<TPropertyLocation[]>(
          `${environment.api}/property-location/all`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );

        if (defaultPropertyLocationId) {
          onValueChange(
            response.data.find(
              propertyLocation => propertyLocation.id === defaultPropertyLocationId
            ) ?? null
          );
        }

        return response.data;
      } catch {
        enqueueSnackbar('Failed to fetch properties.', { variant: 'error' });
      }
    },
  });

  return (
    <Autocomplete
      options={fetchPropertyLocationsQuery.data ?? []}
      value={value}
      onChange={(_, _value) => onValueChange(_value)}
      isOptionEqualToValue={(option, _value) => option.id === _value.id}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          label="Location"
          {...textFieldProps}
          {...params}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {fetchPropertyLocationsQuery.isPending && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
    />
  );
};

export default PropertyLocationAutocomplete;
