import { createTheme } from '@mui/material/styles';

import { PaletteThemeProps } from '@/@mantis/types/theme';

import ThemeOption from './theme';

export default function Palette() {
  const paletteColor: PaletteThemeProps = ThemeOption();

  return createTheme({
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      ...paletteColor,
      text: {
        primary: paletteColor.grey[900],
        secondary: paletteColor.grey[600],
        disabled: paletteColor.grey[400],
      },
      action: {
        disabled: paletteColor.grey[300],
      },
      divider: paletteColor.grey[200],
      background: {
        paper: paletteColor.grey[0],
        default: paletteColor.grey.A50,
      },
    },
  });
}
