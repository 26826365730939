import { Box, Button, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { PublicRoutes } from '@/config/routes';

import BlurBackground from './BlurBackground';

const AboutUsHeroSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 6 }}>
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{ py: 6, display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <BlurBackground
          isStrong
          sx={{
            left: { xs: '50%', md: 0 },
            top: 0,
            transform: 'translate(-50%, -50%)',
          }}
        />

        <Stack spacing={4} sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
          <Typography variant="h1">
            About Terr.ai: <strong>Future of Property&nbsp;Management</strong>
          </Typography>

          <Typography variant="body1" color="text.secondary">
            Revolutionizing property management by leveraging artificial intelligence to streamline
            operations, boost efficiency, and enhance tenant experiences.
          </Typography>

          <Stack
            spacing={1.5}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', md: 'initial' }}
          >
            {/* <Button
                variant="outlined"
                startIcon={<PlayCircleOutlineOutlinedIcon color="primary" fontSize="large" />}
                size="large"
                color="secondary"
              >
                Book a Demo
              </Button> */}

            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ order: { xs: -1, sm: 0 } }}
              href={PublicRoutes.login}
            >
              Get Started
            </Button>
          </Stack>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 6 }} sx={{ position: 'relative' }}>
        <BlurBackground
          isStrong
          sx={{
            right: { xs: '50%', md: 0 },
            top: '100%',
            transform: 'translate(50%, -50%)',
          }}
        />

        <Box
          sx={{
            borderTopLeftRadius: { xs: 64, md: 160 },
            overflow: 'hidden',
            maxHeight: { xs: '50dvw', md: 'initial' },
          }}
        >
          <img
            src="/assets/images/aboutus-hero.png"
            alt="About Us"
            style={{
              width: '100%',
            }}
          />
        </Box>
      </Grid2>
    </Grid2>
  </Grid2>
);

export default AboutUsHeroSection;
