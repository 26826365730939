export default function OverridesTabs() {
  return {
    MuiTabs: {
      styleOverrides: {
        vertical: {
          overflow: 'visible',
        },
      },
    },
  };
}
