import { Theme } from '@mui/material/styles';

export default function OverridesListItemText(theme: Theme) {
  return {
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'subtitle1',
        },
        secondaryTypographyProps: {
          variant: 'subtitle2',
        },
      },
    },
  };
}
