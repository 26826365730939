import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { TKnowledge } from '@/types/knowledge';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Trash01Icon } from '@/assets/icons/Trash01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  knowledge: TKnowledge;
  onClose: () => void;
  onKnowledgeDeleted: (knowledgeId: TKnowledge) => void;
}

const KnowledgeDeleteModal = ({ knowledge, onClose, onKnowledgeDeleted }: Props) => {
  const { session } = useAuth();

  const deleteKnowledgeMutation = useMutation({
    mutationFn: async () => {
      await axios.delete(`${environment.api}/question/${knowledge.id}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });
    },
    onSuccess: () => {
      onKnowledgeDeleted(knowledge);
      onClose();
      enqueueSnackbar('Knowledge item deleted successfully.', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete knowledge item.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this Knowledge Item?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          variant="contained"
          color="error"
          startIcon={<Trash01Icon />}
          loading={deleteKnowledgeMutation.isPending}
          onClick={() => deleteKnowledgeMutation.mutate()}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default KnowledgeDeleteModal;
