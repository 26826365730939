import { Theme } from '@mui/material/styles';

import Typography from '../typography';

export default function OverridesDataGrid(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          border: '1px solid',
          borderRadius: '12px',
          borderColor: theme.palette.divider,
          flex: '1 1 100px',

          '&.MuiDataGrid-root--densityComfortable': {
            fontSize: '14px',
          },

          '& .MuiDataGrid-row--borderBottom': {
            borderBottom: '1px solid',
            borderColor: theme.palette.divider,
          },

          '& .MuiDataGrid-columnHeader': {
            maxHeight: '44px',
            lineHeight: '44px',
          },

          '& .MuiDataGrid-columnHeaderTitle': {
            ...Typography().caption,
            color: theme.palette.text.secondary,
          },

          '& .MuiDataGrid-columnSeparator': {
            color: 'transparent',
          },

          '& .MuiDataGrid-row': {
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.divider,
          },

          '& .MuiDataGrid-footerContainer': {
            backgroundColor: 'transparent',
            borderTop: '1px solid',
            borderTopColor: theme.palette.divider,
            flex: '0 0 auto',
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },

          '& .MuiDataGrid-cell': {
            color: theme.palette.text.secondary,
          },

          '& .MuiDataGrid-cell a': {
            color: theme.palette.secondary.darker,
            fontWeight: 600,
          },

          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },

          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },

          '& .MuiTablePagination-selectLabel': {
            display: 'none',
          },

          '& .MuiTablePagination-input': {
            display: 'none',
          },

          '& .MuiTablePagination-toolbar': {
            paddingLeft: '1rem',
            paddingRight: '1rem',
            width: '100%',
          },

          '& .MuiDataGrid-row--dynamicHeight .MuiDataGrid-cell': {
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
            display: 'flex',
            alignItems: 'center',
          },

          '&&.MuiDataGrid-root--densityComfortable .MuiDataGrid-row--dynamicHeight .MuiDataGrid-cell':
            {
              paddingTop: '1rem',
              paddingBottom: '1rem',
            },
        },
      },
    },
  };
}
