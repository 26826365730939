import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '& #notistack-snackbar > .MuiSvgIcon-root': {
    marginRight: '0.5rem',
  },

  '& .MuiIconButton-root': {
    color: theme.palette.common.white,
  },
  '& .MuiIconButton-root:hover': {
    color: theme.palette.text.primary,
  },
  '&.notistack-MuiContent-default': {
    background: theme.palette.primary.main,
  },
  '&.notistack-MuiContent-error': {
    background: theme.palette.error.main,
  },
  '&.notistack-MuiContent-success': {
    background: theme.palette.success.main,
  },
  '&.notistack-MuiContent-info': {
    background: theme.palette.info.main,
  },
  '&.notistack-MuiContent-warning': {
    background: theme.palette.warning.main,
  },
}));

export default StyledSnackbarProvider;
