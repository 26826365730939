import { Box, Dialog, DialogContent, DialogTitle, Skeleton, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { environment } from '@env';

import { TDocument } from '@/types/document';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { XCircleIcon } from '@/assets/icons/XCircleIcon';
import { useAuth } from '@/context/AuthProvider';

import MuiPDFDocument from '@/components/document/MuiPDFDocument';

interface Props {
  id: string;
  onClose: () => void;
}

const DocumentViewModal: React.FC<Props> = ({ id, onClose }) => {
  const { session } = useAuth();

  const documentUrl = `${environment.api}/document/doc/${id}?token=${session?.access_token}`;

  const fetchDocumentQuery = useQuery<TDocument>({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'document', id, session?.access_token],
    queryFn: async () => {
      const response = await axios.get(`${environment.api}/document/${id}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data;
    },
  });

  const isImage = fetchDocumentQuery.data?.mime_type.startsWith('image/');

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      sx={{
        '.MuiPaper-root': {
          height: '100%',
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {fetchDocumentQuery.isPending ? (
            <Skeleton animation="wave" sx={{ width: '230px' }} />
          ) : (
            <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {fetchDocumentQuery.data?.original_name}
            </Box>
          )}

          <IconButton onClick={onClose} color="secondary">
            <XCircleIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ flex: '0 0 calc(100% - 68px)', display: 'flex' }}>
        <Box sx={{ flex: '1 1 100%', overflow: 'auto' }}>
          {isImage ? (
            <img src={documentUrl} alt={fetchDocumentQuery.data?.original_name || id} />
          ) : (
            <MuiPDFDocument
              url={documentUrl}
              fileName={fetchDocumentQuery.data?.original_name || id}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentViewModal;
