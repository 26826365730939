import { Grid2, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { PublicRoutes } from '@/config/routes';

const FooterSection: React.FC = () => (
  <Grid2 size={12}>
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        pb: 3,
        pt: 5,
        borderTop: '1px solid #D9D9D9',
        lineHeight: 1,
      }}
    >
      <Link to={PublicRoutes.home}>
        <img src="/assets/images/terrai-logo.svg" alt="Terrai" style={{ height: 28 }} />
      </Link>

      <Typography variant="body2" color="text.disabled">
        © {new Date().getFullYear()} Terr.AI. All rights reserved.
      </Typography>
    </Stack>
  </Grid2>
);

export default FooterSection;
