import { setupIonicReact } from '@ionic/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PostHogProvider } from 'posthog-js/react';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { environment } from '@env';

import { HOPRoutes, PMPRoutes, PublicRoutes } from '@/config/routes';
import AuthProvider from '@/context/AuthProvider';
import { isAssociaSubdomain } from '@/utils/communities';

import ConditionalRoute from '@/components/ConditionalRoute';

const AboutUsPage = lazy(() => import('@/pages/public/AboutUsPage'));
const AuthPage = lazy(() => import('@/pages/public/AuthPage'));
const ChatPage = lazy(() => import('@/pages/public/ChatPage'));
// const HomePage = lazy(() => import('@/pages/public/HomePage'));
const LogoutPage = lazy(() => import('@/pages/public/LogoutPage'));
const PrivacyPolicyPage = lazy(() => import('@/pages/public/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('@/pages/public/TermsOfServicePage'));
const AssociaHomePage = lazy(() => import('@/pages/associa/AssociaHomePage'));
const LandingPage = lazy(() => import('@/pages/public/LandingPage'));
const HOPDashboardPage = lazy(() => import('@/pages/home-owner-portal/DashboardPage'));
const HOPDocumentListPage = lazy(() => import('@/pages/home-owner-portal/DocumentListPage'));
const HOPDocumentViewPage = lazy(() => import('@/pages/home-owner-portal/DocumentViewPage'));
const HOPPropertyCreatePage = lazy(() => import('@/pages/home-owner-portal/PropertyCreatePage'));

const PMPHomePage = lazy(() => import('@/pages/property-manager-portal/HomePage'));

const options = {
  api_host: environment.posthogUrl,
};

setupIonicReact();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App: React.FC = () => (
  <PostHogProvider apiKey={environment.posthogKey} options={options}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Suspense>
            <Switch>
              <Route
                exact
                path={PublicRoutes.home}
                component={isAssociaSubdomain() ? AssociaHomePage : LandingPage}
              />

              <Route exact path={PublicRoutes.login} component={AuthPage} />
              <Route exact path={PublicRoutes.signup} component={AuthPage} />
              <Route exact path={PublicRoutes.logout} component={LogoutPage} />
              <Route exact path={PublicRoutes.privacyPolicy} component={PrivacyPolicyPage} />
              <Route exact path={PublicRoutes.termsOfService} component={TermsOfServicePage} />
              <Route exact path={PublicRoutes.aboutUs} component={AboutUsPage} />
              <ConditionalRoute exact path={PublicRoutes.chat} component={ChatPage} />

              <ConditionalRoute exact path={HOPRoutes.dashboard} component={HOPDashboardPage} />
              <ConditionalRoute exact path={HOPRoutes.propertyList}>
                <Redirect to={HOPRoutes.dashboard} />
              </ConditionalRoute>
              <ConditionalRoute
                exact
                path={HOPRoutes.propertyCreate}
                component={HOPPropertyCreatePage}
              />
              <ConditionalRoute
                exact
                path={HOPRoutes.documentList}
                component={HOPDocumentListPage}
              />
              <ConditionalRoute
                exact
                path={HOPRoutes.documentView}
                component={HOPDocumentViewPage}
              />

              <ConditionalRoute path={PMPRoutes.dashboard} component={PMPHomePage} />

              <Route>
                <Redirect to={PublicRoutes.home} />
              </Route>
            </Switch>
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </PostHogProvider>
);

export default App;
