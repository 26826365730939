import Box from '@mui/material/Box';

const HeaderContent: React.FC = () => (
  <>
    <Box sx={{ width: '100%', ml: 1 }} />

    {/* {!downLG && <FullScreen />} */}
    {/* <Profile /> */}
  </>
);

export default HeaderContent;
