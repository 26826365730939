import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import BlurBackground from './BlurBackground';

const features = [
  {
    title: 'Emergency Response',
    description:
      'When an emergency situation that could affect the health of your building is communicated to your Terr.ai Copilot, your property manager will get various communications to ensure that the proper action can be taken by your team.',
  },
  {
    title: 'Zero Platform Changes',
    description:
      'No need to change platforms or revamp your existing system, terr ai is easily implemented through a new email and phone number. Terr.ai ensures that your tenants will receive better answers, better communication and will save you a ton of time and money.',
  },
  {
    title: 'Smart Service Synthesis',
    description:
      'Every interaction builds your knowledge base. Your copilot tracks and analyzes communications across all units and buildings, creating actionable tickets and spotting trends before they become issues. Turn scattered data into strategic insights that enhance your entire operation.',
  },
];

const FeaturesSection: React.FC = () => (
  <Grid2 size={12} sx={{ position: 'relative' }}>
    <BlurBackground
      sx={{
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />

    <Box sx={{ py: 6, textAlign: 'center' }}>
      <Stack spacing={0.75} sx={{ maxWidth: 770, margin: '0 auto' }}>
        <Box>
          <Box
            sx={{
              py: 0.5,
              px: 1.5,
              borderRadius: '16px',
              border: '1px solid #D9D9D9',
              color: '#9D1500',
              margin: '0 auto',
              display: 'inline-flex',
              height: '30px',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption" sx={{ lineHeight: 1 }} color="primary">
              AI Copilot
            </Typography>
          </Box>
        </Box>

        <Typography variant="h2" mb={1}>
          Property Management that
          <br />
          <strong>responds, adapts and learns.</strong>
        </Typography>

        <Typography variant="body1">
          From emergency responses to daily tasks, your AI copilot handles it all. Seamlessly
          integrate with your existing platforms while building smarter workflows.
        </Typography>

        <Box sx={{ mt: 4, mb: -2 }}>
          <img src="/assets/images/mockup-features.png" alt="Features" style={{ width: '100%' }} />
        </Box>
      </Stack>

      <Box sx={{ borderTop: '1px solid #D9D9D9', mt: -2 }}>
        <Grid2 container>
          {features.map(feature => (
            <Grid2 key={feature.title} size={{ xs: 12, sm: 4 }}>
              <Stack spacing={0.5} sx={{ p: 2.5 }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {feature.title}
                </Typography>

                <Typography variant="body2">{feature.description}</Typography>
              </Stack>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </Box>
  </Grid2>
);

export default FeaturesSection;
