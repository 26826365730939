import { Box, SxProps } from '@mui/material';

interface Props {
  isStrong?: boolean;
  sx?: SxProps;
}

const BlurBackground: React.FC<Props> = ({ isStrong = false, sx }) => (
  <Box
    sx={{
      position: 'absolute',
      width: '100dvw',
      height: '100dvw',
      background: isStrong
        ? 'radial-gradient(50% 50% at 50% 50%, rgba(157, 21, 0, 0.15) 0%, rgba(157, 21, 0, 0) 100%)'
        : 'radial-gradient(50% 50% at 50% 50%, rgba(157, 21, 0, 0.07) 0%, rgba(157, 21, 0, 0) 100%)',
      zIndex: -1,
      ...sx,
    }}
  />
);

export default BlurBackground;
