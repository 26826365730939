import { Link } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { useCallback, useRef, useState, useMemo } from 'react';

import { TDocument } from '@/types/document';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Trash01Icon } from '@/assets/icons/Trash01Icon';
import { PMPRoutes } from '@/config/routes';
import { useDocumentStatus } from '@/context/DocumentStatusProvider';
import { getFromNow } from '@/utils/date';
import { getResolvedRoutePath } from '@/utils/router';

import DocumentStatusChip from '@/components/document/DocumentStatusChip';
import { API as DocumentUploadButtonAPI } from '@/components/document/DocumentUploadButton';

interface DocumentListStateConfig {
  onDocumentDeleted?: () => void;
}

export const useDocumentListState = (config: DocumentListStateConfig = {}) => {
  const { onDocumentDeleted: onDocumentDeletedCallback } = config;

  const { documentStatuses, documentProgress } = useDocumentStatus();

  const [isDocumentDeleteModalOpen, setIsDocumentDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<TDocument | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const documentUploadButtonRef = useRef<DocumentUploadButtonAPI>(null);

  const onUploadDocumentClick = useCallback(() => {
    documentUploadButtonRef.current?.click();
  }, [documentUploadButtonRef]);

  const onDeleteDocumentClick = useCallback((document: TDocument) => {
    setDocumentToDelete(document);
    setIsDocumentDeleteModalOpen(true);
  }, []);

  const handleOnDocumentDeleted = useCallback(() => {
    setDocumentToDelete(null);
    setIsDocumentDeleteModalOpen(false);
    onDocumentDeletedCallback?.();
  }, [onDocumentDeletedCallback]);

  const onSortModelChange = useCallback(
    (_sortModel: GridSortModel) => setSortModel(_sortModel),
    []
  );

  const onDocumentDeleteModalClose = useCallback(() => {
    setIsDocumentDeleteModalOpen(false);
  }, []);

  const processDocuments = useCallback(
    (documents: TDocument[]) =>
      documents.map(document => ({
        ...document,
        status: documentStatuses[document.id] || document.status,
        progress: documentProgress[document.id] ?? document.progress,
      })),
    [documentStatuses, documentProgress]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'original_name',
        headerName: 'Name',
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<TDocument, TDocument['original_name']>) => (
          <Link href={getResolvedRoutePath(PMPRoutes.documentView, { id: params.row.id })}>
            {params.value}
          </Link>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params: GridRenderCellParams<TDocument, TDocument['status']>) => (
          <DocumentStatusChip status={params.value} />
        ),
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        width: 150,
        valueFormatter: (value: TDocument['created_at']) => getFromNow(new Date(value)),
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 52,
        renderCell: (params: GridRenderCellParams<TDocument>) => (
          <LoadingButton
            aria-label="Delete Document"
            shape="square"
            color="secondary"
            size="small"
            onClick={() => onDeleteDocumentClick(params.row)}
          >
            <Trash01Icon />
          </LoadingButton>
        ),
      },
    ],
    [onDeleteDocumentClick]
  );

  return {
    // State
    isDocumentDeleteModalOpen,
    documentToDelete,
    sortModel,

    // Refs
    documentUploadButtonRef,

    // Handlers
    onUploadDocumentClick,
    onDeleteDocumentClick,
    handleOnDocumentDeleted,
    onSortModelChange,
    onDocumentDeleteModalClose,

    // Utils
    processDocuments,

    // UI
    columns,
  };
};
