import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';

import { environment } from '@env';

import { TBuildingContact } from '@/types/building';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { useAuth } from '@/context/AuthProvider';
import { formatPhoneNumber } from '@/utils/phone';

interface Props {
  buildingId: number;
  onClose: () => void;
  onSMSBulkSend?: () => void;
}

const SMSBulkSendModal: React.FC<Props> = ({ buildingId, onClose, onSMSBulkSend }) => {
  const [message, setMessage] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<TBuildingContact[]>([]);

  const { session } = useAuth();

  const fetchBuildingContactsQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'building', buildingId, 'contacts', session?.access_token],
    queryFn: async () => {
      try {
        const response = await axios.get<TBuildingContact[]>(
          `${environment.api}/building/${buildingId}/contacts`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );

        const contactsWithPhone = response.data.filter(contact => contact.phone);

        setSelectedContacts(contactsWithPhone);

        return contactsWithPhone;
      } catch {
        enqueueSnackbar('Failed to fetch building contacts.', { variant: 'error' });
      }
    },
  });

  // Reset selected contacts and select all valid contacts when contacts data changes
  useEffect(() => {
    if (!fetchBuildingContactsQuery.data) return;

    setSelectedContacts(fetchBuildingContactsQuery.data);
  }, [fetchBuildingContactsQuery.data]);

  const bulkSendSMSMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        `${environment.api}/twilio/bulk-send`,
        {
          tenantIds: selectedContacts.map(contact => contact.tenantId),
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      onSMSBulkSend?.();
      enqueueSnackbar('Bulk SMS sent successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to send bulk SMS.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Bulk SMS</DialogTitle>

      <DialogContent>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Recipients ({selectedContacts.length} selected)
        </Typography>

        <Autocomplete
          multiple
          value={selectedContacts}
          onChange={(_, newValue) => setSelectedContacts(newValue)}
          options={fetchBuildingContactsQuery.data || []}
          getOptionLabel={option =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            `${option.tenantName} (Unit ${option.unitName}) <${formatPhoneNumber(option.phone!)}>`
          }
          renderInput={params => <TextField {...params} label="Select Recipients" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          loading={fetchBuildingContactsQuery.isLoading}
          sx={{ mb: 2 }}
        />

        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
          Message
        </Typography>

        <TextField
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Enter your message here..."
          helperText={`Message will be sent to ${selectedContacts.length} recipients`}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          onClick={() => bulkSendSMSMutation.mutate()}
          variant="contained"
          startIcon={<Send01Icon />}
          disabled={
            !message.trim() || selectedContacts.length === 0 || fetchBuildingContactsQuery.isPending
          }
          loading={bulkSendSMSMutation.isPending}
        >
          Send SMS
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SMSBulkSendModal;
