import { Theme } from '@mui/material/styles';

export default function OverridesDialogContentText(theme: Theme) {
  return {
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          color: theme.palette.text.primary,
        },
      },
    },
  };
}
