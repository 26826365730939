import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { EyeIcon } from '@/assets/icons/EyeIcon';

const OurVisionSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 6 }}>
    <Grid2 container spacing={4}>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <Box
          sx={{
            borderTopRightRadius: { xs: 64, md: 160 },
            overflow: 'hidden',
            maxHeight: { xs: '50vw', md: 'initial' },
          }}
        >
          <img src="/assets/images/aboutus-vision.png" alt="Our Vision" style={{ width: '100%' }} />
        </Box>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack spacing={3} sx={{ p: { md: 8 } }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: '50%',
              background: '#9D150033',
              border: '4px solid #9D15000D',
              outline: '4px solid #9D15000D',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EyeIcon color="primary" />
          </Box>

          <Stack spacing={2}>
            <Typography variant="h2" sx={{ fontWeight: 600 }}>
              Our Vision
            </Typography>

            <Typography variant="body1" color="text.secondary">
              At Terr.ai, we envision a future where property management is effortless, intelligent,
              and accessible to everyone. Our AI-powered platform transforms complex
              property-related tasks into simple, streamlined processes, empowering property
              managers and owners to focus on what truly matters - delivering exceptional service
              and value.
            </Typography>
          </Stack>
        </Stack>
      </Grid2>
    </Grid2>
  </Grid2>
);

export default OurVisionSection;
