import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TPropertyLocation } from '@/types/propertyLocation';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  propertyLocation: TPropertyLocation;
  onClose: () => void;
  onPropertyLocationUpdated: (propertyLocation: TPropertyLocation) => void;
}

const PropertyLocationUpdateModal: React.FC<Props> = ({
  propertyLocation,
  onClose,
  onPropertyLocationUpdated,
}) => {
  const [name, setName] = useState(propertyLocation.name);

  const { session } = useAuth();

  const editPropertyLocationMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.put<TPropertyLocation>(
        `${environment.api}/property-location/${propertyLocation.id}`,
        { name },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onPropertyLocationUpdated(data);
      enqueueSnackbar('Property updated successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update property.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Edit Property</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2} mt={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          disabled={!name}
          variant="contained"
          color="primary"
          startIcon={<Save01Icon />}
          loading={editPropertyLocationMutation.isPending}
          onClick={() => editPropertyLocationMutation.mutate()}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PropertyLocationUpdateModal;
