import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useUpdateItemQueryData = <T>(queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  return useCallback(
    (updater: (_data: T) => T) => {
      queryClient.setQueryData<T>(queryKey, _data => updater(_data as T));
    },
    [queryClient, queryKey]
  );
};
