export default function OverridesInputBase() {
  return {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem',
        },
      },
    },
  };
}
