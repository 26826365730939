import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback } from 'react';

import { environment } from '@env';

import { TDocument } from '@/types/document';

import { useUpdateListQueryData } from '@/hooks/useUpdateListQueryData';

interface UseDocumentUploadParams {
  entityType: 'building' | 'tenant' | 'unit';
  entityId: string;
  accessToken: string | undefined;
}

interface UseDocumentUploadReturn {
  onFileSelect: (file: File) => void;
  isUploading: boolean;
}

export const useDocumentUpload = ({
  entityType,
  entityId,
  accessToken,
}: UseDocumentUploadParams): UseDocumentUploadReturn => {
  const updateDocumentList = useUpdateListQueryData<TDocument>([
    'GET',
    entityType,
    entityId,
    'document',
    accessToken,
  ]);

  const uploadDocumentMutation = useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post<TDocument>(
        `${environment.api}/${entityType}/${entityId}/document`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const document: TDocument = {
        ...response.data,
        status: 'PROCESSING',
        progress: 0,
      };

      updateDocumentList(_documents => [document, ..._documents]);

      return document;
    },
  });

  const onFileSelect = useCallback(
    (file: File) => {
      uploadDocumentMutation.mutate(file);
    },
    [uploadDocumentMutation]
  );

  return {
    onFileSelect,
    isUploading: uploadDocumentMutation.isPending,
  };
};
