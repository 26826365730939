import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';

import { environment } from '@env';

import { TKnowledge } from '@/types/knowledge';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  knowledge: TKnowledge | null;
  onClose: () => void;
  onKnowledgeUpdated: (knowledge: TKnowledge) => void;
}

const KnowledgeUpdateModal: React.FC<Props> = ({ knowledge, onClose, onKnowledgeUpdated }) => {
  const { session } = useAuth();

  const [question, setQuestion] = useState(knowledge?.question || '');
  const [answer, setAnswer] = useState(knowledge?.answer || '');

  const updateKnowledgeMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.put(
        `${environment.api}/question`,
        {
          question_id: knowledge?.id,
          question,
          answer,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      onKnowledgeUpdated(data);
      enqueueSnackbar('Knowledge item updated successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update knowledge item.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="edit-knowledge-dialog">Edit Knowledge</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Update the knowledge item. Only changed fields will be sent to the server.
        </DialogContentText>
        <TextField
          fullWidth
          label="Question / Topic"
          value={question}
          onChange={e => setQuestion(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Answer / Guidance"
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>

        <LoadingButton
          disabled={!question || !answer}
          variant="contained"
          color="primary"
          startIcon={<Save01Icon />}
          loading={updateKnowledgeMutation.isPending}
          onClick={() => updateKnowledgeMutation.mutate()}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default KnowledgeUpdateModal;
