import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';

import { environment } from '@env';

import { TKnowledge } from '@/types/knowledge';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { useAuth } from '@/context/AuthProvider';

interface AddQuestionDialogProps {
  onClose: () => void;
  onKnowledgeCreated: (knowledge: TKnowledge) => void;
}

const KnowledgeCreateModal: React.FC<AddQuestionDialogProps> = ({
  onClose,
  onKnowledgeCreated,
}) => {
  const { session } = useAuth();

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const createKnowledgeMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TKnowledge>(
        `${environment.api}/question`,
        {
          question,
          answer,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      onKnowledgeCreated(data);
      enqueueSnackbar('Knowledge item added successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to add knowledge item.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle id="add-knowledge-dialog">Add Knowledge Item</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Enter any knowledge that you want Terr.ai to learn. This will help generate the right
          responses to inbound tenant questions and requests.
        </DialogContentText>
        <TextField
          fullWidth
          label="Question / Topic"
          value={question}
          onChange={e => setQuestion(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Answer / Guidance"
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <LoadingButton
          disabled={!question || !answer}
          variant="contained"
          color="primary"
          startIcon={<PlusIcon />}
          loading={createKnowledgeMutation.isPending}
          onClick={() => createKnowledgeMutation.mutate()}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default KnowledgeCreateModal;
