import { IonSpinner } from '@ionic/react';
import { Input, Stack, Typography } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { pdfjs, Document } from 'react-pdf';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';

import { BaseProps } from '@/types/props';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { ArrowCircleLeftIcon } from '@/assets/icons/ArrowCircleLeftIcon';
import { ArrowCircleRightIcon } from '@/assets/icons/ArrowCircleRightIcon';
import { DownloadCloud01Icon } from '@/assets/icons/DownloadCloud01Icon';
import { ZoomInIcon } from '@/assets/icons/ZoomInIcon';
import { ZoomOutIcon } from '@/assets/icons/ZoomOutIcon';
import { useResizeObserver } from '@/hooks/useResizeObserver';
import { trimZeroes } from '@/utils/input';
import { clamp } from '@/utils/number';

import S from './MuiPDFDocument.styles';
import PDFPage from './PDFPage';

interface Props extends BaseProps {
  url: string;
  fileName?: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const ZOOM_STEP = 25;
const ZOOM_MIN = 100;
const ZOOM_MAX = 600;

const MuiPDFDocument: React.FC<Props> = ({ url, fileName = 'Untitled Document', ...baseProps }) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [hasLoaded, setHasLoaded] = useState(false);
  const pageInputRef = useRef<HTMLInputElement | null>(null);
  const zoomInputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [docContainerWidth, setDocContainerWidth] = useState<number>(0);
  const [docContainerHeight, setDocContainerHeight] = useState<number>(0);

  const onResize = useCallback<ResizeObserverCallback>(entries => {
    const [entry] = entries;

    if (entry) {
      setDocContainerWidth(entry.contentRect.width);
      setDocContainerHeight(entry.contentRect.height);
    }
  }, []);

  const onLoadSuccess: OnDocumentLoadSuccess = useCallback(data => {
    setNumPages(data.numPages);
    setHasLoaded(true);
  }, []);

  useResizeObserver(containerRef.current, onResize);

  const handleSetCurrentPage = useCallback(
    (pageNumber: number) => {
      setCurrentPage(Math.floor(clamp(pageNumber, 1, numPages)));

      trimZeroes(pageInputRef);
    },
    [numPages]
  );

  const handleSetZoomLevel = useCallback((_zoomLevel: number, shouldClamp = true) => {
    if (shouldClamp) {
      setZoomLevel(Math.floor(clamp(_zoomLevel, ZOOM_MIN, ZOOM_MAX)));
    } else {
      setZoomLevel(Math.floor(_zoomLevel));
    }

    trimZeroes(zoomInputRef);
  }, []);

  return (
    <S.ContainerBox>
      <S.DocumentBox>
        <Document
          file={url}
          onLoadSuccess={onLoadSuccess}
          loading={<IonSpinner name="circular" />}
          error={<IonSpinner name="circular" />}
        >
          <PDFPage
            key={currentPage}
            pageNumber={currentPage}
            containerHeight={docContainerHeight}
            containerWidth={docContainerWidth}
            scale={zoomLevel / 100}
          />
        </Document>
      </S.DocumentBox>

      {numPages > 1 && (
        <>
          <S.PreviousPageIconButton
            shape="rounded"
            size="large"
            color="primary"
            disabled={currentPage === 1}
            onClick={() => handleSetCurrentPage(currentPage - 1)}
          >
            <ArrowCircleLeftIcon />
          </S.PreviousPageIconButton>

          <S.NextPageIconButton
            shape="rounded"
            size="large"
            color="primary"
            disabled={currentPage === numPages}
            onClick={() => handleSetCurrentPage(currentPage + 1)}
          >
            <ArrowCircleRightIcon />
          </S.NextPageIconButton>
        </>
      )}

      {hasLoaded && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack direction="row" spacing={1}>
            <IconButton
              color="secondary"
              disabled={zoomLevel <= ZOOM_MIN}
              onClick={() => handleSetZoomLevel(zoomLevel - ZOOM_STEP)}
            >
              <ZoomOutIcon />
            </IconButton>

            <Input
              type="number"
              size="small"
              value={zoomLevel}
              inputProps={{
                ref: zoomInputRef,
                min: ZOOM_MIN,
                max: ZOOM_MAX,
                step: ZOOM_STEP,
              }}
              onChange={e => handleSetZoomLevel(Number(e.target.value), false)}
            />

            <IconButton
              color="secondary"
              disabled={zoomLevel >= ZOOM_MAX}
              onClick={() => handleSetZoomLevel(zoomLevel + ZOOM_STEP)}
            >
              <ZoomInIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              color="secondary"
              disabled={currentPage === 1}
              onClick={() => handleSetCurrentPage(currentPage - 1)}
            >
              <ArrowCircleLeftIcon />
            </IconButton>
            <Input
              type="number"
              size="small"
              inputProps={{
                ref: pageInputRef,
                min: 1,
                max: numPages,
              }}
              value={currentPage}
              onChange={e => handleSetCurrentPage(Number(e.target.value))}
            />
            <Typography variant="caption">/</Typography>
            <Typography variant="caption">{numPages}</Typography>

            <IconButton
              color="secondary"
              disabled={currentPage === numPages}
              onClick={() => handleSetCurrentPage(currentPage + 1)}
            >
              <ArrowCircleRightIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={1} p={1}>
            <IconButton
              color="secondary"
              disabled={zoomLevel === 100}
              onClick={() => handleSetZoomLevel(100)}
            >
              <ZoomOutIcon />
            </IconButton>

            <IconButton
              color="secondary"
              // @ts-ignore
              href={url}
              download={fileName}
              target="_blank"
            >
              <DownloadCloud01Icon />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </S.ContainerBox>
  );
};

export default MuiPDFDocument;
