import Logo from '@/@mantis/components/logo';

import DrawerHeaderStyled from './DrawerHeaderStyled';

interface Props {
  open: boolean;
}

const DrawerHeader: React.FC<Props> = ({ open }) => (
  <DrawerHeaderStyled
    open={open}
    sx={{
      minHeight: '60px',
      width: 'initial',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: open ? '24px' : 0,
    }}
  >
    <Logo isIcon={!open} sx={{ width: open ? 'auto' : 35, height: 35 }} />
  </DrawerHeaderStyled>
);

export default DrawerHeader;
