import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';

import { environment } from '@env';

import { TDocument } from '@/types/document';

import { useDocumentListState } from './useDocumentListState';
import { useDocumentUpload } from './useDocumentUpload';

type EntityType = 'building' | 'tenant' | 'unit';

interface UseDocumentListConfig {
  entityType: EntityType;
  entityId: string;
  accessToken?: string;
  onDocumentDeleted?: () => void;
}

export const useDocumentList = ({
  entityType,
  entityId,
  accessToken,
  onDocumentDeleted,
}: UseDocumentListConfig) => {
  const documentState = useDocumentListState({ onDocumentDeleted });

  const documentUpload = useDocumentUpload({
    entityType,
    entityId,
    accessToken,
  });

  const fetchDocumentsQuery = useQuery({
    enabled: !!accessToken,
    queryKey: ['GET', entityType, entityId, 'document', accessToken],
    queryFn: async () => {
      try {
        const response = await axios.get<TDocument[]>(
          `${environment.api}/${entityType}/${entityId}/document`,
          {
            headers: accessToken ? {
              Authorization: `Bearer ${accessToken}`,
            } : undefined,
          },
        );
        return response.data;
      } catch {
        enqueueSnackbar('Failed to fetch documents.', { variant: 'error' });
      }
    },
  });

  const documents = useMemo(
    () => documentState.processDocuments(fetchDocumentsQuery.data ?? []),
    [fetchDocumentsQuery.data, documentState],
  );

  return {
    // Data
    documents,
    isLoading: fetchDocumentsQuery.isLoading,

    // Layout props - commonly used props with sensible defaults
    layoutProps: {
      documents,
      columns: documentState.columns,
      isLoading: fetchDocumentsQuery.isLoading,
      sortModel: documentState.sortModel,
      onSortModelChange: documentState.onSortModelChange,
      documentUploadButtonRef: documentState.documentUploadButtonRef,
      isFileUploading: documentUpload.isUploading,
      onFileSelect: documentUpload.onFileSelect,
      onUploadDocumentClick: documentState.onUploadDocumentClick,
      isDocumentDeleteModalOpen: documentState.isDocumentDeleteModalOpen,
      documentToDelete: documentState.documentToDelete,
      onDocumentDeleteModalClose: documentState.onDocumentDeleteModalClose,
      onDocumentDeleted: documentState.handleOnDocumentDeleted,
    },

    // Individual state
    ...documentState,
    ...documentUpload,
  };
};
