import { Box, Grid2, Theme } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import React, { PropsWithChildren } from 'react';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';

const themeOverrides = (theme: Theme) =>
  merge(cloneDeep(theme), {
    typography: {
      h1: {
        fontWeight: 500,
        strong: {
          fontWeight: 700,
        },
      },
      h2: {
        fontWeight: 500,
        strong: {
          fontWeight: 700,
        },
      },
      h3: {
        fontWeight: 500,
        strong: {
          fontWeight: 700,
        },
      },
      h4: {
        fontWeight: 500,
        strong: {
          fontWeight: 700,
        },
      },
      h5: {
        fontWeight: 500,
        strong: {
          fontWeight: 700,
        },
      },
      body1: {
        strong: {
          fontWeight: 600,
        },
      },
      body2: {
        strong: {
          fontWeight: 600,
        },
      },
      caption: {
        fontWeight: 500,
        strong: {
          fontWeight: 600,
        },
      },
    },
  });

const BasePublicMuiPage: React.FC<PropsWithChildren> = ({ children }) => (
  <MuiPageWrapper themeOverrides={themeOverrides}>
    <Box sx={{ overflowX: 'hidden' }}>
      <Box
        sx={{
          maxWidth: 1280,
          width: '100%',
          p: 1.5,
          margin: '0 auto',
          backgroundImage: 'url(/assets/images/background-pattern.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '1920px 1440px',
          backgroundPosition: 'top center',
          em: {
            fontWeight: 500,
            fontStyle: 'normal',
          },
        }}
      >
        <Grid2 container spacing={2}>
          {children}
        </Grid2>
      </Box>
    </Box>
  </MuiPageWrapper>
);

export default BasePublicMuiPage;
